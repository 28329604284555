<template>
      <div class="vx-row">
        <div class="vx-col w-full mb-base">
          <vx-card title="" class="mb-5">
            <vs-tabs>
                  <vs-tab label="Info" class='pt-5'>

                        <div class="vx-row">
                          <div class="vx-col w-full lg:w-1/2" >
                              
                              <table class="info sm:w-full md:w-full">
                                <tr>
                                  <td class="font-semibold">Company Name.</td>
                                  <td>{{dt.company_name}}</td>
                                </tr>
                                <tr>
                                  <td class="font-semibold">Packing List No.</td>
                                  <td>{{dt.code}}</td>
                                </tr>
                                <tr>
                                  <td class="font-semibold">Date</td>
                                  <td>{{dt.date_manifest | moment("dddd, Do MMMM YYYY")}}</td>
                                </tr>
                                <tr>
                                  <td class="font-semibold">Route</td>
                                  <td>{{dt.route}}</td>
                                </tr>
                                <tr>
                                  <td class="font-semibold">Type</td>
                                  <td>{{dt.type.toUpperCase()}}</td>
                                </tr>
                                <tr>
                                  <td class="font-semibold">No. Reff</td>
                                  <td>{{dt.no_reff}}</td>
                                </tr>
                                <tr>
                                  <td class="font-semibold">Description</td>
                                  <td>{{dt.description}}</td>
                                </tr>
                              </table>
                          </div>
                          <div class="vx-col w-full lg:w-1/2">
                              
                              <table class="info">
                                <tr>
                                  <td class="font-semibold">ETD</td>
                                  <td>{{dt.etd | moment("dddd, Do MMMM YYYY - HH:mm")}}</td>
                                </tr>
                                <tr>
                                  <td class="font-semibold">ETA</td>
                                  <td>{{dt.eta | moment("dddd, Do MMMM YYYY - HH:mm")}}</td>
                                </tr>
                              </table>
                          </div>      
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full">
                                <vs-divider></vs-divider>
                                <h4 class='mb-5 mt-5'>Goods / Commodities</h4>
                                <vs-table :data="details" class="mb-3">
                                  <template slot="thead">
                                    <vs-th sort-key="jo_code">Job Order No.</vs-th>
                                    <vs-th sort-key="commodity">Goods / Commodity</vs-th>
                                    <vs-th sort-key="unit">Unit</vs-th>
                                    <vs-th sort-key="weight">Weight (Kg)</vs-th>
                                    <vs-th sort-key="volume">Volume (m<sup>3</sup>)</vs-th>
                                    <vs-th sort-key="qty">Transporting</vs-th>
                                  </template>
                                  <template slot-scope="{data}">
                                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                      <vs-td :data="tr.jo_code">
                                        <router-link :to="{name:'job_orders-show',params:{id: tr.jo_id}}">{{tr.jo_code}}</router-link>
                                      </vs-td>
                                      <vs-td label="Goods / Commodity" :data="tr.commodity">{{tr.commodity}}</vs-td>
                                      <vs-td label="Unit" :data="tr.unit">{{tr.unit}}</vs-td>
                                      <vs-td label="Weight (Kg)" class='text-right' :data="tr.weight">{{tr.weight}}</vs-td>
                                      <vs-td label="Volume (m<sup>3</sup>)" class='text-right' :data="tr.volume">{{tr.volume}}</vs-td>
                                      <vs-td label="Transporting" class='text-right' :data="tr.qty">{{tr.qty}}</vs-td>
                                    </vs-tr>
                                  </template>
                                </vs-table>
                            </div>
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full">
                                <vs-divider></vs-divider>
                                <div class='mt-8 mb-5'>
                                    <h4 class='float-left'>Manifest Costs</h4>
                                    <vs-button class='float-right' v-if="dt.current_status_id != 8" type="gradient" :to="{name: 'manifests-add-cost', params:{id: $route.params.id}}">Add Cost</vs-button>
                                </div>
                                <br>
                                <br>
                                <vs-table :data="cost_lists" class="mb-3 mt-5">
                                      <template slot="thead">
                                        <vs-th>Name</vs-th>
                                        <vs-th>Vendor</vs-th>
                                        <vs-th>Qty</vs-th>
                                        <vs-th>Cost</vs-th>
                                        <vs-th>Total Cost</vs-th>
                                        <vs-th>Description</vs-th>
                                        <vs-th>Status</vs-th>
                                        <vs-th></vs-th>
                                      </template>
                                      <template slot-scope="{data}">
                                        <vs-tr :key="i" v-for="(tr, i) in data">
                                          <vs-td>{{tr.name}}</vs-td>
                                          <vs-td label="Vendor">{{tr.vendor}}</vs-td>
                                          <vs-td label="Qty" class='text-right'>{{tr.qty|numFormat}}</vs-td>
                                          <vs-td label="Cost" class='text-right'>{{tr.price|numFormat}}</vs-td>
                                          <vs-td label="Total Cost" class='text-right'>{{tr.total_price|numFormat}}</vs-td>
                                          <vs-td label="Description">{{tr.description}}</vs-td>
                                          <vs-td>
                                            <vs-chip v-if="tr.status==1" color="warning">Draft</vs-chip>
                                            <vs-chip v-if="tr.status==2" color="primary">Approved</vs-chip>
                                          </vs-td>
                                          <vs-td width="10">
                                            <div class="flex">
                                              <div v-if="dt.current_status_id != 8">
                                                <vs-button v-if="tr.status==1" size="small" type="line" :to="{name:'manifests-edit-cost', params:{id : $route.params.id,cost_id: tr.id}}" icon-pack="feather" icon="icon-edit"></vs-button>
                                                <vs-button v-if="tr.status==1" size="small" type="line" icon-pack="feather" icon="icon-check-circle" color="primary" @click="approveCost(tr.id)"></vs-button>
                                                <vs-button v-if="tr.status==1" size="small" type="line" icon-pack="feather" icon="icon-trash" color="danger" @click="deleteCost(tr.id)"></vs-button>
                                              </div>
                                            </div>
                                          </vs-td>
                                        </vs-tr>
                                      </template>
                                    </vs-table>

                                    <div class="flex">
                                      <vs-button type="border" :to="{name: 'manifests'}" color="warning">Back</vs-button>
                                    </div>
                            </div>
                        </div>
                  </vs-tab>
                  <vs-tab label="Shipment">
                      <shipment class='mt-3' :delivery_order_id='dt.delivery_order_id' />
                  </vs-tab>
            </vs-tabs>
          </vx-card>
        </div>
      </div>

</template>

<script>
import { mapActions } from 'vuex'
import Shipment from '@pages/operational/shipments/Show'

export default {
  data(){
    return {
      dt: {},
      details: [],
      cost_lists: [],
      idDeleteCost: null,
      idApproveCost: null
    }
  },
  components : {
     Shipment
  },
  methods:{
    ...mapActions({
      dispatchShow: 'manifests/show',
      dispatchShowCommodities: 'manifests/get_commodities',
      dispatchShowCosts: 'manifests/get_costs'
    }),
    async getData(){
      let { data } = await this.dispatchShow(this.$route.params.id)
      this.dt = data
    },
    async getDetails(){
      let { data } = await this.dispatchShowCommodities(this.$route.params.id)
      this.details = data
    },
    async getCosts(){
      let { data } = await this.dispatchShowCosts(this.$route.params.id)
      this.cost_lists = data
    },
    approveCost(id){
      this.idApproveCost = id
      this.$vs.dialog({
        type: `confirm`,
        color: `primary`,
        title: `Are you sure ?`,
        text: `This cost will be approved.`,
        accept: this.confirmApproveCost
      })
    },
    async confirmApproveCost(){
      try {
        await this.$store.dispatch('manifests/approve_costs', this.idApproveCost)
        this.getCosts()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been approved successfully`,
          color: `primary`
        })
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
    deleteCost(id){
      this.idDeleteCost = id
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmDeleteCost
      })
    },
    async confirmDeleteCost(){
      try {
        await this.$store.dispatch('manifests/delete_costs', this.idDeleteCost)
        this.getCosts()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been deleted successfully`,
          color: `primary`
        })
      } catch (error) {
        // console.log(error);
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
  },
  mounted(){
    this.getData()
    this.getDetails()
    this.getCosts()
  }
}
</script>

<style>

</style>